import React from 'react';
import AboutDetail from '../components/About/AboutDetail';
// import AboutCounts from '../components/About/AboutCounts';
import FrameWorkSkills from '../components/About/FrameWorkSkills';
import LanguageSkills from '../components/About/LanguageSkills';

import DelayFn from '../hoc/DelayFn';

const About = ({ location: { pathname } }) => {
  console.log(pathname);
  let cssName = 'about';
  if (pathname) {
    cssName = 'about section-show';
  }
  return (
    <section id="about" className={cssName}>
      <div className="about-me container">
        <div className="section-title">
          <h2>About</h2>
          <p>Learn more about me</p>
        </div>
        <AboutDetail />
        {/* <AboutCounts /> */}
        <LanguageSkills />
        <FrameWorkSkills />
      </div>
    </section>
  );
};

export default DelayFn(About);
// export default About;
