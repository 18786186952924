import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ProgressBar from './ProgressBar';
const FrameWorkSkills = () => {
  const [cssOn, setCssOn] = useState(false);
  const turnOnCss = () => {
    setCssOn(true);
  };
  return (
    <div className="skills container">
      <div className="section-title">
        <h2>Language</h2>
      </div>
      <div className="row skills-content">
        <div className="col-lg-6">
          <ProgressBar title="JAVASCRIPT" percent="100%" cssOn={cssOn} />
          <ProgressBar title="object-c" percent="90%" cssOn={cssOn} />
          <ProgressBar title="PYTHON" percent="80%" cssOn={cssOn} />
        </div>
        <div className="col-lg-6">
          <ProgressBar title="SWIFT" percent="80%" cssOn={cssOn} />
          <ProgressBar title="C" percent="80%" cssOn={cssOn} />
          <ProgressBar title="SCSS/CSS" percent="70%" cssOn={cssOn} />
        </div>
      </div>{' '}
      <Waypoint onEnter={turnOnCss} />
    </div>
  );
};
export default FrameWorkSkills;
