import React from 'react';
import personalImage from '../../assets/img/Simon.jpg';
const AboutDetail = () => {
  return (
    <div className="row">
      <div className="col-lg-4" data-aos="fade-right">
        <img src={personalImage} className="img-fluid" alt="" />
      </div>
      <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
        <h3>MERN Full Stack &amp; iOS Engineer </h3>
        <p className="font-italic">
          Seeking for a full-time Software Engineer or Full Stack Engineer
          position starting immediately.
        </p>
        <div className="row">
          <div className="col-lg-6">
            <ul>
              <li>
                <i className="icofont-rounded-right"></i>{' '}
                <strong>Website:</strong> www.simonho.app
              </li>

              <li>
                <i className="icofont-rounded-right"></i> <strong>City:</strong>{' '}
                Los Angeles, CA
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                <i className="icofont-rounded-right"></i>{' '}
                <strong>Degree:</strong> Master
              </li>
              <li>
                <i className="icofont-rounded-right"></i>{' '}
                <strong>Email:</strong> hoshihchuan@gmail.com
              </li>
            </ul>
          </div>
        </div>
        <p>
          During master study, I have participated in couples of Hackathons.
          Since I participated in these activities, I started to contact other
          areas expect iOS. In these different areas, I realized that my
          skillset and knowledge were not sufficient because I like challenging
          myself with different experiences, and this makes me a better person,
          so I started to study the backend and frontend fullstack areas.
        </p>
      </div>
    </div>
  );
};

export default AboutDetail;
