import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header';
import About from './pages/About';
import MobileNav from './components/MobileNav/MobileNav';
import Resume from './pages/Resume';
// import Services from './pages/Services';
import Contact from './pages/Contact';

function App(props) {
  const [styleState, setStyleState] = useState({
    style: '',
    iconBtn: 'icofont-navigation-menu',
  });

  const changeStyle = () => {
    if (styleState.style) {
      setStyleState({ style: '', iconBtn: 'icofont-navigation-menu' });
    } else {
      setStyleState({
        style: 'mobile-nav-active',
        iconBtn: 'icofont-close',
      });
    }
  };
  return (
    <div className={styleState.style}>
      <Header />
      <Switch>
        <Route exact path="/" />
        <Route component={About} exact path="/about" />
        <Route component={Resume} exact path="/resume" />
        {/* <Route component={Services} exact path="/services" /> */}
        <Route component={Contact} exact path="/contact" />
      </Switch>
      <button
        onClick={changeStyle}
        type="button"
        className="mobile-nav-toggle d-lg-none"
      >
        <i className={styleState.iconBtn}></i>
      </button>
      <div class="mobile-nav-overly"></div>
      <MobileNav changeStyle={changeStyle} />
      <div class="credits">
        UI Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
  );
}

export default App;
