import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const Header = (props) => {
  const [state, setState] = useState({ header: '' });
  const [active, setActive] = useState({ home: 'active' });
  let location = useLocation().pathname;

  useEffect(() => {
    switch (location) {
      case '/':
        setActive({ home: 'active' });
        break;
      case '/about':
        setActive({ about: 'active' });
        break;
      case '/resume':
        setActive({ resume: 'active' });
        break;
      case '/services':
        setActive({ services: 'active' });

        break;
      case '/contact':
        setActive({ contact: 'active' });

        break;
      default:
        setState({ header: '' });
    }

    if (location === '/') {
      setState({ header: '' });
    } else {
      setState({ header: 'header-top' });
    }
  }, [location]);

  // const onLinkClick = (e) => {
  //   // e.target.className = 'active';
  //   console.log(e.target.className);
  // };
  // console.log(pathname);

  const test = () => {
    setState({ header: '' });
  };
  return (
    <header id="header" className={state.header}>
      <div className="container">
        <h1>
          <Link to="/">Simon Ho</Link>
        </h1>
        <h2>
          I'm a passionate <span>Full Stack Engineer</span> &amp;{' '}
          <span>iOS developer</span> from Los Angeles
        </h2>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            {/* <li className="active"> */}
            <li className={active.home}>
              <Link to="/" onClick={test}>
                Home
              </Link>
            </li>
            <li className={active.about}>
              <Link
                to="/about"
                onClick={() => setState({ header: 'header-top' })}
              >
                About
              </Link>
            </li>
            <li className={active.resume}>
              <Link to="/resume">Resume</Link>
            </li>
            {/* <li className={active.services}>
              <Link to="/services">Services</Link>
            </li> */}
            {/* <li>
              <a href="#portfolio">Portfolio</a>
            </li> */}
            <li className={active.contact}>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>

        <div class="social-links">
          <a
            href="https://www.linkedin.com/in/simon-shih-chuan-ho-b10812b5/"
            class="linkedin"
            target="_blank"
          >
            <i class="icofont-linkedin"></i>
          </a>
          <a
            href="https://github.com/wanwanzainaer"
            class="linkedin"
            target="_blank"
          >
            <i class="ri-github-fill"></i>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
