import React from 'react';
import { Link } from 'react-router-dom';
const MobileNav = ({ changeStyle }) => {
  return (
    <nav className="mobile-nav d-lg-none">
      <ul>
        {/* <li className="active"> */}
        <li>
          <Link to="/" onClick={changeStyle}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={changeStyle}>
            About
          </Link>
        </li>
        <li>
          <Link to="/resume" onClick={changeStyle}>
            Resume
          </Link>
        </li>
        {/* <li>
          <Link to="/services" onClick={changeStyle}>
            Services
          </Link>
        </li> */}
        {/* <li>
          <a href="#portfolio">Portfolio</a>
        </li> */}
        <li>
          <Link to="/contact" onClick={changeStyle}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNav;
