import React from 'react';
import DelayFn from '../hoc/DelayFn';

const Contact = ({ location: { pathname } }) => {
  console.log(pathname);
  let cssName = 'contact';
  if (pathname) {
    cssName = 'contact section-show';
  }
  return (
    <section id="contact" className={cssName}>
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
          <p>Contact Me</p>
        </div>

        <div className="row mt-2">
          <div className="col-md-6 d-flex align-items-stretch">
            <div className="info-box">
              <i className="bx bx-envelope"></i>
              <h3>Email Me</h3>
              <p>hoshihchuan@gmail.com</p>
            </div>
          </div>

          <div className="col-md-6 mt-4 mt-md-0 d-flex align-items-stretch">
            <div className="info-box">
              <i className="bx bx-share-alt"></i>
              <h3>Social Profiles</h3>
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/in/simon-shih-chuan-ho-b10812b5/"
                  className="linkedin"
                >
                  <i className="icofont-linkedin"></i>
                </a>
                <a href="https://github.com/wanwanzainaer" className="linkedin">
                  <i className="ri-github-fill"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DelayFn(Contact);
