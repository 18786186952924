import React from 'react';

const ProgressBar = ({ title, percent, cssOn }) => {
  let cssStyle = {};
  if (cssOn) {
    cssStyle = { width: percent };
  }
  return (
    <div className="progress">
      <span className="skill">
        {title} <i className="val">{percent}</i>
      </span>
      <div className="progress-bar-wrap">
        <div
          className="progress-bar"
          role="progressbar"
          style={cssStyle}
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
};
export default ProgressBar;
