import React from 'react';
import DelayFn from '../hoc/DelayFn';

const Resume = ({ location: { pathname } }) => {
  console.log(pathname);
  let cssName = 'resume';
  if (pathname) {
    cssName = 'resume section-show';
  }
  return (
    <section id="resume" className={cssName}>
      <div className="container">
        <div className="section-title">
          <h2>Resume</h2>
          <p>Check My Resume</p>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <h3 className="resume-title">Sumary</h3>
            <div className="resume-item pb-0">
              <h4>Software Engineer</h4>
              <p>
                <ul>
                  <li>
                    <em>2 years iOS Engineer work experience</em>
                  </li>
                  <li>
                    <em> more than 1 year AWS hand on experience</em>
                  </li>{' '}
                  <li>
                    <em>more than 1 year React and Redux hand on experience</em>
                  </li>
                  <li>
                    <em>
                      {' '}
                      more than 1 year Node.js with Express and MongoDB database
                      hand on experience,
                    </em>
                  </li>
                  <li>
                    <em>1 years Serverless Framework hand on experience,</em>
                  </li>
                </ul>
              </p>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
              <h4>Master of Science Information Technology</h4>
              <h5>2017 - 2019</h5>
              <p>
                <em>California Lutheran Uniiiversity, Thousand Oaks, CA</em>
              </p>
            </div>
            <div className="resume-item">
              <h4>Bachelor degree Industrial Engineering and Management</h4>
              <h5>2006 - 2010</h5>
              <p>
                <em>Huafan University, New Taipei City, Taiwan</em>
              </p>
            </div>
            <h3 className="resume-title">Certificate</h3>
            <div className="resume-item pb-0">
              <h4>AWS Certified Solutions Architect – Associate</h4>
              <h5>Dec. 2019 - Dec. 2022</h5>
            </div>
            <h3 className="resume-title">Projects</h3>
            <div className="resume-item">
              <h4>The Board Service</h4>
              <h5>May 2020 - June 2020</h5>
              <p>
                “https://theboardservice.com” which is like bulletin board, make
                student to post something to sell, want or rent house.
              </p>
              <p>
                <ul>
                  <li>
                    Frontend: Materil-UI, React, Redux, Reudx-Saga, S3
                    presign-url , hosted by S3
                  </li>
                  <li>
                    Backend: Main API NodeJS with Express, MongoDB, Sub API
                    Serverless Framework(Lambda, Dynamo DB), hosted by heroku
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <h3 className="resume-title">Professional Experience</h3>
            <div className="resume-item">
              <h4>Software Engineer Intern</h4>
              <h5>Dec. 2018 - Feb. 2019</h5>
              <p>
                <em>TAP Series, Westlake Village, CA </em>
              </p>
              <p>
                <ul>
                  <li>
                    Web crawler utilized Python 3 and Beautiful Soup 4 to crawl
                    older website information
                  </li>
                  <li>
                    Utilized Python 3 automatic generated different size and
                    style Nutrition Facts labels.
                  </li>
                  <li>
                    Built RWD website using jQuery, Bootstrap, CSS, and HTML
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-item">
              <h4>iOS Developer</h4>
              <h5>Dec. 2015 - Jun. 2016</h5>
              <p>
                <em>Sesh Electronics Crop, Taiwan</em>
              </p>
              <p>
                <ul>
                  <li>
                    Utilized BLE 4.0 connect and control car sensor, and app can
                    run on the background
                  </li>
                  <li>
                    Utilized Google map to navigation and record driving path
                    and update to the online.
                  </li>
                  <li>
                    If driver get some car accident, the sensor will send the
                    SOS message via iPhone.
                  </li>
                </ul>
              </p>
            </div>
            <div className="resume-item">
              <h4>iOS Developer</h4>
              <h5>Dec. 2015 - Jun. 2016</h5>
              <p>
                <em>Sunming-Eye Clinic, Taiwan</em>
              </p>
              <p>
                <ul>
                  <li>
                    Build iPhone app for patient to store info and iPad app for
                    clinic to manage data.
                  </li>
                  <li>
                    iPhone app utilize CoreData to store their physiology
                    information and present it with line graph
                  </li>
                  <li>
                    iPhone app can download medicine info which provide by iPad
                    app.
                  </li>
                  <li>
                    iPhone app can make appointment, save clinic front desk 40%
                    time then phone call.
                  </li>
                  <li>
                    iPhone app can scan QRcode which include the medicine info
                    and receive the notification to remind patients take
                    medicines
                  </li>
                  <li>
                    iPad app include Medicine Inventory Management System, CRUD
                    medicine info, receive patients’ appointment and print out
                    QRCode with the information the patient need.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DelayFn(Resume);
