import React, { useEffect, useState } from 'react';

const DelayFn = (WrapComponent) =>
  function Comp(props) {
    const [timeout, setTimeoutState] = useState(false);

    useEffect(() => {
      setTimeout(() => setTimeoutState(true), 350);
    }, []);

    return timeout ? <WrapComponent {...props} /> : null;
  };

export default DelayFn;
