import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ProgressBar from './ProgressBar';
const FrameWorkSkills = () => {
  const [cssOn, setCssOn] = useState(false);
  const turnOnCss = () => {
    setCssOn(true);
  };
  return (
    <div className="skills container">
      <div className="section-title">
        <h2>Skills</h2>
      </div>
      <div className="row skills-content">
        <div className="col-lg-6">
          <ProgressBar title="React" percent="100%" cssOn={cssOn} />
          <ProgressBar title="Redux + Saga" percent="100%" cssOn={cssOn} />
          <ProgressBar title="AWS" percent="90%" cssOn={cssOn} />
          <ProgressBar title="MySQL" percent="80%" cssOn={cssOn} />
        </div>
        <div className="col-lg-6">
          <ProgressBar title="NodeJS" percent="100%" cssOn={cssOn} />
          <ProgressBar
            title="Serverless Framework"
            percent="85%"
            cssOn={cssOn}
          />{' '}
          <ProgressBar title="MongoDB" percent="90%" cssOn={cssOn} />
          <ProgressBar title="IoT" percent="70%" cssOn={cssOn} />
        </div>
      </div>
      <Waypoint onEnter={turnOnCss} />
    </div>
  );
};
export default FrameWorkSkills;
